import {
  CognitoUser,
  cognitoUserFactory,
} from "utils/factories/cognitoUserFactory"

export const getCognitoUser = async (
  userId: string
): Promise<CognitoUser | null> => {
  try {
    const response = await fetch("/api/find-user", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ userId }),
      next: {
        revalidate: 0,
      },
    })
    const data = await response.json()

    return cognitoUserFactory(data.user)
  } catch (error) {
    console.error("Error fetching IP address:", error)
    return null
  }
}
